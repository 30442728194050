//$bookitup-light-orange: #FFBE00 !default;
$bookitup-light-orange: #FFEDD6 !default;

//$bookitup-deep-orange: #ff6600 !default;
$bookitup-deep-orange: #fc6262 !default;
$bookitup-deep-black: #282828 !default;

$bookitup-new-orange: #fc4d4a !default;
$bookitup-new-light-orange: #ff514f !default;

//$primary: $bookitup-light-orange !default;
//$primary: #7a87ca !default;
$primary: #1D5191 !default;
$primary-takeoff: #1A4983 !default;
$primary-hover: #ffffff !default;
// gold c9af87
$secondary: #FF5C5A !default;
$secondary-takeoff: #FF6C6B !default;
$secondary-hover: #ffffff !default;

//$secondary: #c1bdb5 !default;
$default: #c1bdb5 !default;
//$secondary: rgba(0, 0, 0, .03)  !default;
//$success: $bookitup-deep-orange !default;
$warning: $bookitup-light-orange !default;

$info: $primary;
$warning: $bookitup-light-orange;
$success: #4EBF46;
$link-color: #6E6E6E;

body {
  font-family: 'Nunito Sans' !important;
}

.link-style-danger {
  color: #ff3b30 !important;
  font-weight: 700;
  font-family: 'Circular' !important;
}

.link-style-danger:hover {
  color: #cc2f26 !important;
  text-decoration: none !important;
}

.link-style-blue {
  color: #1b72b7 !important;
  font-weight: 700;
  font-family: 'Circular' !important;
}

.link-style-blue:hover {
  color: #395d80 !important;
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.navigation-list {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
}
.navigation-list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  overflow: -moz-scrollbars-none;
}

// show PDFs bigger in FileModalDialog
.react-pdf__Page__canvas {
  max-width: 650px;
}

.react-pdf__Page__textContent {
  display: none;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transition: transform 0.5s;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@import '../node_modules/react-tippy/dist/tippy.css';
@import 'fonts.scss';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'Atoms/bootstrap-component-adjustments.scss';
@import 'Atoms/toast-component-adjustments.scss';
@import 'Atoms/Dropdowns.scss';
@import 'Atoms/Buttons.scss';
@import 'Molecules/Dialogs.scss';
@import 'Templates/Navigation/Navigation';
@import 'Molecules/Searchbar.scss';
@import 'Molecules/Eventlist.scss';
@import 'Organismns/Navigation/ListGroupItem';
@import 'Atoms/bootstrap-breadcrumb.scss';
@import 'Atoms/Switch.scss';
@import 'Organismns/ProfitView.scss';
@import 'Atoms/Selectfield.scss';
@import 'Templates/Settings/WebHooks/WebsiteConnect';
@import 'Molecules/Placeholder.scss';
@import 'Molecules/DynamicFieldWrapper.scss';
@import 'Atoms/InputFields.scss';
@import 'Atoms/InputFields-DayPicker.scss';
@import 'Molecules/ActionBar.scss';
@import 'Atoms/Table.scss';
@import 'Molecules/Pane.scss';
@import 'Atoms/Tabs.scss';
@import 'Atoms/InputFields-Validation.scss';
@import 'Atoms/InputFields-Radio.scss';
@import 'Atoms/InputFields-Checkbox';
@import 'Atoms/InputFields-Text';
@import 'Atoms/modal.scss';
@import 'Atoms/common.scss';
@import 'Molecules/MessageEditor.scss';
@import 'Atoms/buttons/Clickable.scss';
@import 'Atoms/Autocomplete.scss';
@import 'Atoms/Formatter/SimpleLabelWrapper';
@import 'Atoms/inputs/SimpleEditableText.scss';
@import 'Organismns/Tour/tour.scss';
@import 'Atoms/DayPicker.scss';
@import '../node_modules/react-datepicker/dist/react-datepicker.css';
@import 'Atoms/inputs/EditableDateInput';
@import 'Utils/hover-min.css';
@import 'Atoms/inputs/RadioBox.scss';
@import 'Atoms/inputs/TextSelectionPopover.scss';
@import 'Atoms/ToastManager.scss';
@import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
@import 'Atoms/Calendar/react-calendar-adjustments.scss';
@import 'Atoms/SectionStyle.scss';
@import 'recommendation-pipeline.scss';
@import 'Atoms/inputs/EditableTaskListItem.scss';
@import 'Organismns/Tasks/AllTasksList.scss';
@import 'bookitup-form-builder/dist/app.css';
@import 'Atoms/Timeline.scss';

textarea,
select,
input,
button,
div {
  outline: none;
}
html, body{
  min-width: fit-content;
}

body {
  font-size: 0.875rem;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Nunito Sans' !important;
  //background-color: #ffffff;
  background-color: rgb(251, 251, 251);
  //padding-bottom: 70px;
}

.container {
  padding: 0;
}

@media (max-width: 768px) {
  .container {
    max-width: none;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  padding-left: 0px;
  margin: 0;
  border: 0;
}

.custom-control-label {
  margin-bottom: 14px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  padding-right: 1rem;
}

.form-header {
  color: #fff;
  text-align: center;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  -webkit-border-radius: 0.125rem;
  border-radius: 0.125rem;
  padding: 75px 100px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 5px 8px;
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}

.page-header {
  /* Container just in case we want more than just the search input to come along */
  position: fixed;
  top: 52px;
  input {
    transition: width 0.2s;
    -webkit-appearance: none; /* Autoprefixer doesn't catch this */
  }
}

.top {
  height: 54px; /* Space in here for search */
  padding-top: 0px;
  position: relative;
  z-index: 150;
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
  .fix-search & {
    background: #eee;
  }
}

.page-header {
  /* Container just in case we want more than just the search input to come along */
  position: fixed;
  top: 52px;
  z-index: 100;
  input {
    transition: width 0.2s;
    -webkit-appearance: none; /* Autoprefixer doesn't catch this */
  }
  .fix-page-header & {
    position: fixed;
    top: 0px;
    input {
    }
  }
}

.animated-icon:hover {
  transform: translateZ(0);
  display: inline-block;
  vertical-align: middle;
}

.log-events__vertical-line {
  position: absolute;
  top: 10px;
  bottom: 0;
  color: #e3e3e3;
  -webkit-transform: translateX(-21px);
  transform: translateX(-21px);
  height: 100%;
  z-index: 0;
}

.state-point {
  position: absolute;
  transform: translateX(-18px);
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 50%;
}

// animation for hot plan
.upDownAnimation {
  animation-name: upDown;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes upDown {
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateY(8px);
  }
}

.white-shadow-box {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 8px #00000026;
  margin: 10px;
}

.intercom-lightweight-app {
  display: none;
}

.logo {
    @media (max-width: 992px) {
      visibility: hidden;
    }
}

.mantine-Checkbox-input {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.notHover {
  pointer-events: none;
  background-color: white !important;
}

input {
  -webkit-appearance: none !important;;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hover-class {
  &:hover {
    background-color: #E8EEF4;
  }
}


.mention {
  margin-left: 5px;
  margin-right: 2px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(241, 241, 241);
  user-select: none;
  padding: 2px 5px;

}

.unresolved {
  display: inline-block;
  color: #E65351;
  background-color: #FFEFEF;
  padding: 2px 5px;
  margin-right: 2px;
  border-radius: 4px;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
  pointer-events: none;
}

th .fc-col-header-cell {
  background-color: #4A4A4A;
  font-family: 'Nunito Sans' !important;
}

.fc-toolbar-chunk > h2 {
  font-family: 'Nunito Sans' !important;
}


.fc .fc-col-header-cell-cushion {
  display: inline-block;
  color: white;
  font-family: 'Nunito Sans' !important;
}

.fc-daygrid-day-top > a {
  color: black;
  font-family: 'Nunito Sans' !important;
  font-size: 16px;
}

.fc-prev-button {
  background-color: #1D5191 !important;
  border: none !important;
}

.fc-next-button {
  background-color: #1D5191 !important;
  border: none !important;
}

.fc-dayGridMonth-button {
  background-color: #1D5191 !important;
}

.fc-button-group {
  background-color: rgb(241, 241, 241);
  border-radius: 10px !important;
}

.fc-button-group > button {
  background-color: #f1f1f1 !important;
  color: black !important;
  font-family: 'Nunito Sans' !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 0.3em 1em !important;
}

.fc-button-group > button[aria-pressed="true"] {
  background-color: #1D5191 !important;
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px;
}

.fc-today-button {
  background-color: #1D5191 !important;
  color: white !important;
  border: none !important;
}

.fc-timegrid-divider {
  height: 2px !important;
  background-color: #e2e2e2 !important;
}

.responsive-text {
  width: 100%;             /* Ensures the text takes up the full width of its container */
  max-width: 100%;         /* Prevents the text from exceeding the width of its container */
  white-space: nowrap;     /* Prevents the text from wrapping to a new line */
  overflow: hidden;        /* Hides any overflow text */
  text-overflow: ellipsis; /* Adds an ellipsis (...) at the end of the overflowed text */
  box-sizing: border-box;  /* Includes padding and border in the element's total width and height */
}

.pointer {
  cursor: pointer;
}


.wistia_embed > div {
  border-radius: 5px !important;
}

.hooverable-text {
  &:hover {
    color: black;
    font-weight: bolder;
  }
}

.hooverable-tile {
  cursor: pointer;
  &:hover {
    border: 3px solid #1D5191;
  }
}

.hooverable-icon {
  cursor: pointer;
  &:hover {
    color: #6E6E6E;
  }
}

.space-preserve {
  white-space: preserve;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.hooverable-icon-blue {
  cursor: pointer;
  &:hover {
    color: #1D5191;
  }
}

.events-month-indent:not(:has(.event-list-item)) {
  display: none; /* Hide parent if no nested .child-class exists */
}

#events-list-container:not(:has(.event-list-item)) + #empty-events-container {
  display: block !important;
}